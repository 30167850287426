import React from 'react';
import { useForm } from "react-hook-form";
import spinner from "./spinner.gif";

export type Nutrition = {
    calories: number;
    proteins: number;
    carbs: number;
    fats: number;
    item: string;
}

export type FilterInput = Nutrition & {
    McDonalds: boolean;
    Boost: boolean;
    HungryJacks: boolean;
    KFC: boolean;
    GYG: boolean;
}

export type FilterProps = {
    stores: string[];
    updateFilters: (values: FilterInput) => void;
}

function getRandomArbitrary(min: number, max: number) {
    return Math.random() * (max - min) + min;
}  

export const Filters = (props: FilterProps) => {
    const [ submitted, setSubmitted ] = React.useState<boolean>(false);
    const { register, handleSubmit } = useForm<FilterInput>({defaultValues: {McDonalds:true, Boost: true, HungryJacks: true, KFC: true, GYG: true}});

    const onSubmitHandler = (values: FilterInput) => {
        if (submitted) {
            return
        }

        setSubmitted(true);
        setTimeout(() => {
            setSubmitted(false);
            props.updateFilters(values);
        }, 1500 + getRandomArbitrary(-500, 500));
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)} className='query-list' autoComplete='off'>
          <div className='query-item'>
            <h2 className=''>Calories</h2>
            <div className='column-container width-100 left-pad'>
              <input {...register("calories")} id="calories" type="text" className='right-pad' autoComplete="false"/>
            </div>
          </div>
          <div className='query-item'>
            <h2 className=''>Proteins</h2>
            <div className='column-container width-100 left-pad'>
              <input  {...register("proteins")} id="proteins" type="text" autoComplete="false"/>
              <h2 className='right-pad'>g</h2>
            </div>
          </div>
          <div className='query-item'>
            <h2 className=''>Carbs</h2>
            <div className='column-container width-100 left-pad'>
              <input {...register("carbs")} id="carbs" type="text" autoComplete="false"/>
              <h2 className='right-pad'>g</h2>
            </div>
          </div>
          <div className='query-item'>
            <h2 className=''>Fats</h2>
            <div className='column-container width-100 left-pad'>
              <input {...register("fats")} id="fats" type="text"/>
              <h2 className='right-pad'>g</h2>
            </div>
          </div>
          <div className='query-item'>
            <h2 className=''>Item Name</h2>
            <div className='column-container width-100 left-pad'>
              <input {...register("item")} className='right-pad' id="item" type="text"/>
            </div>
          </div>
          <div className='query-item-checkbox'>
            <h2 className=''>Restaurants</h2>
            <div className='query-checkbox left-pad'>
                <div className='single-check'>
                    <span>McDonalds</span>
                    <input {...register("McDonalds")} type="checkbox"/> 
                </div>
                <div className='single-check'>
                    <span>Boost</span>
                    <input {...register("Boost")} type="checkbox"/>
                </div>
                <div className='single-check'>
                    <span>Hungry Jack's</span>
                    <input {...register("HungryJacks")} type="checkbox"/>
                </div>
                <div className='single-check'>
                    <span>KFC</span>
                    <input {...register("KFC")} type="checkbox"/>
                </div>
                <div className='single-check'>
                    <span><b>New 🎉</b> - Guzman y Gomez</span>
                    <input {...register("GYG")} type="checkbox"/>
                </div>
                <div className='single-check'>
                    {/* <span><a href='https://www.google.com'><b>Vote for your favourite to be added next!</b></a></span> */}
                </div>  
            </div>
          </div>
          <div className='submit-item padded'>
            { 
                submitted
                ? <img src={spinner} alt='Loading Spinner'/>
                : <input type="submit" value={"Find Food!"} /> 
            }
          </div>
        </form>
    )
}